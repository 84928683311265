<template>
  <div class="logAdv">
    <div class="title">
      <h2>Log Adv</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form v-model="logAdvs" :inline="true">
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="SourceId(s)" label-width="2.2rem">
                  <group-select
                    v-model="logAdvs.sourceIds"
                    :data-source="sourcesList"
                    multiple
                    :loading="selectLoading"
                    clearable
                    filterable
                    placeholder="请选择"
                    style="width: 3.45rem;"
                    hasAll
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="PullStatus" label-width="2.2rem">
                  <el-select
                    v-model="logAdvs.status"
                    clearable
                    placeholder="请选择"
                    style="width:3.4rem;"
                  >
                    <el-option
                      v-for="item in pullOptions"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Payout Type" label-width="2.2rem">
                  <el-select
                    v-model="logAdvs.payoutType"
                    style="width:3.4rem;"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in typeOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Date" label-width="2.2rem">
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="datetimerange"
                    style="width:3.4rem;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="23" :sm="7" :md="7" :lg="7" :xl="7" :offset="1">
                <el-form-item>
                  <el-button type="primary" @click="getDataList(1)">In Browser</el-button>
                </el-form-item>
              </el-col>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          height="70vmin"
          stripe
          border
          :data="dataList"
          highlight-current-row
        >
          <el-table-column label="Source Id" min-width="85" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source Name"
            prop="sourceName"
            align="center"
            min-width="120"
          ></el-table-column>
          <el-table-column label="Pull Status" prop="pullStatus" align="center" min-width="110">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.pullStatus == 'success'" type="success">成功</el-tag>
              <el-tag v-else-if="scope.row.pullStatus == 'failed'" type="danger">失败</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Error"
            prop="error"
            align="center"
            min-width="140"
          ></el-table-column>
          <el-table-column
            label="Error Type"
            prop="errorType"
            align="center"
            min-width="140"
          ></el-table-column>
          <el-table-column
            label="ActiveOfferCount"
            prop="activeOfferCount"
            align="center"
            min-width="140"
          ></el-table-column>
          <el-table-column
            label="PausedOfferCount"
            prop="pausedOfferCount"
            align="center"
            min-width="150"
          ></el-table-column>
          <el-table-column
            label="DurationMs"
            prop="durationMs"
            align="center"
            min-width="140"
          ></el-table-column>
          <el-table-column
            label="Date"
            prop="startTime"
            align="center"
            width="170"
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { getLogAdv } from 'api/source/index';
  import dateUtils from '@/utils/dateutils.js';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import { myMixin } from '@/mixins/mixins.js';
  import { mapActions, mapState } from 'vuex';
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        pageSize: 20,
        currentPage: 1,
        startToEndDate: '',
        selectLoading: false,
        listLoading: false,
        pullOptions: [
          {
            label: '全部',
            value: 'all',
          },
          {
            label: '成功',
            value: 'success',
          },
          {
            label: '失败',
            value: 'failed',
          },
        ],
        dataList: [],
        total: null,
        typeOption: [
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
          {
            label: 'CPL',
            value: 'CPL',
          },
        ],
        logAdvs: {
          sourceIds: [],
          pullStatus: 'all',
          type: 'CPI',
          fromDate: null,
          toDate: null,
        },
      };
    },
    computed: {
      ...mapState('source', {
        sourceLoading: (state) => state.sourceLoading,
        sourcesList: (state) => state.sourcesList,
      }),
    },
    mounted() {
      this.getSourceIdsList();

      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
    },
    methods: {
      ...mapActions('source', ['getSourceIdsList']),
      getDataList(curPage) {
        this.listLoading = true;
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          ...this.logAdvs,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (Array.isArray(param.sourceIds)) {
          param.sourceIds = param.sourceIds.join(',');
        }
        if (this.startToEndDate.length === 2) {
          const startDate = this.startToEndDate[0];
          const endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }

        getLogAdv(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            this.dataList = response.result;
            this.total = response.total;
          }
        });
      },
      sourcedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/source/detail',
          query: {
            sourceId: row.sourceId,
          },
        });
        window.open(href, '_blank');
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.getDataList();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
